import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/AutoDocLayout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Name`}</h2>
    <p><strong parentName="p">{`step crypto keypair`}</strong>{` -- generate a public / private keypair in PEM format`}</p>
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-raw"
      }}>{`step crypto keypair <pub_file> <priv_file>
[--kty=<key-type>] [--curve=<curve>] [--size=<size>]
[--password-file=<file>] [--no-password] [--insecure]
`}</code></pre>
    <h2>{`Description`}</h2>
    <p><strong parentName="p">{`step crypto keypair`}</strong>{` generates a raw public /
private keypair in PEM format. These keys can be used by other operations
to sign and encrypt data, and the public key can be bound to an identity
in a CSR and signed by a CA to produce a certificate.`}</p>
    <p>{`Private keys are encrypted using a password. You'll be prompted for this
password automatically when the key is used.`}</p>
    <h2>{`Positional arguments`}</h2>
    <p><inlineCode parentName="p">{`pub_file`}</inlineCode>{`
The path to write the public key.`}</p>
    <p><inlineCode parentName="p">{`priv_file`}</inlineCode>{`
The path to write the private key.`}</p>
    <h2>{`Options`}</h2>
    <p><strong parentName="p">{`--kty`}</strong>{`=`}<inlineCode parentName="p">{`kty`}</inlineCode>{`
The `}<inlineCode parentName="p">{`kty`}</inlineCode>{` to build the certificate upon.
If unset, default is EC.`}</p>
    <p><inlineCode parentName="p">{`kty`}</inlineCode>{` is a case-sensitive string and must be one of:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`EC`}</strong>{`: Create an `}<strong parentName="p">{`elliptic curve`}</strong>{` keypair`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`OKP`}</strong>{`: Create an octet key pair (for `}<strong parentName="p">{`"Ed25519"`}</strong>{` curve)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`RSA`}</strong>{`: Create an `}<strong parentName="p">{`RSA`}</strong>{` keypair`}</p>
      </li>
    </ul>
    <p><strong parentName="p">{`--size`}</strong>{`=`}<inlineCode parentName="p">{`size`}</inlineCode>{`
The `}<inlineCode parentName="p">{`size`}</inlineCode>{` (in bits) of the key for RSA and oct key types. RSA keys require a
minimum key size of 2048 bits. If unset, default is 2048 bits for RSA keys and 128 bits for oct keys.`}</p>
    <p><strong parentName="p">{`--crv`}</strong>{`=`}<inlineCode parentName="p">{`curve`}</inlineCode>{`, `}<strong parentName="p">{`--curve`}</strong>{`=`}<inlineCode parentName="p">{`curve`}</inlineCode>{`
The elliptic `}<inlineCode parentName="p">{`curve`}</inlineCode>{` to use for EC and OKP key types. Corresponds
to the `}<strong parentName="p">{`"crv"`}</strong>{` JWK parameter. Valid curves are defined in JWA `}{`[RFC7518]`}{`. If
unset, default is P-256 for EC keys and Ed25519 for OKP keys.`}</p>
    <p><inlineCode parentName="p">{`curve`}</inlineCode>{` is a case-sensitive string and must be one of:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`P-256`}</strong>{`: NIST P-256 Curve`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`P-384`}</strong>{`: NIST P-384 Curve`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`P-521`}</strong>{`: NIST P-521 Curve`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Ed25519`}</strong>{`: Ed25519 Curve`}</p>
      </li>
    </ul>
    <p><strong parentName="p">{`--from-jwk`}</strong>{`=`}<inlineCode parentName="p">{`jwk-file`}</inlineCode>{`
Create a PEM representing the key encoded in an
existing `}<inlineCode parentName="p">{`jwk-file`}</inlineCode>{` instead of creating a new key.`}</p>
    <p><strong parentName="p">{`--password-file`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
The path to the `}<inlineCode parentName="p">{`file`}</inlineCode>{` containing the password to encrypt or decrypt the private key.`}</p>
    <p><strong parentName="p">{`--no-password`}</strong>{`
Do not ask for a password to encrypt a private key. Sensitive key material will
be written to disk unencrypted. This is not recommended. Requires `}<strong parentName="p">{`--insecure`}</strong>{` flag.`}</p>
    <p><strong parentName="p">{`--insecure`}</strong></p>
    <p><strong parentName="p">{`-f`}</strong>{`, `}<strong parentName="p">{`--force`}</strong>{`
Force the overwrite of files without asking.`}</p>
    <h2>{`Exit codes`}</h2>
    <p>{`This command returns 0 on success and >0 if any error occurs.`}</p>
    <h2>{`Examples`}</h2>
    <p>{`Create an RSA public / private key pair with 4096 bits:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step crypto keypair foo.pub foo.key --kty RSA --size 4096
`}</code></pre>
    <p>{`Create an RSA public / private key with fewer than the recommended number of
bits (recommended \`= 2048 bits):`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step crypto keypair foo.pub foo.key --kty RSA --size 1024 --insecure
`}</code></pre>
    <p>{`Create an EC public / private key pair with curve P-521:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step crypto keypair foo.pub foo.key --kty EC --curve "P-521"
`}</code></pre>
    <p>{`Create an EC public / private key pair but do not encrypt the private key file:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step crypto keypair foo.pub foo.key --kty EC --curve "P-256" \\
--no-password --insecure
`}</code></pre>
    <p>{`Create an Octet Key Pair with curve Ed25519:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step crypto keypair foo.pub foo.key --kty OKP --curve Ed25519
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      